import React, { Fragment, useEffect, useState } from 'react';
import { FileText, LogIn, Mail, Settings, User } from 'react-feather';
import { Media } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { H6, Image, LI, UL } from '../../AbstractElements';
import UserImg from '../../assets/images/avtar/man.png';
import ItemCart from './ItemCart';
import MaxMiniSize from './MaxMiniSize';
import MoonLight from './MoonLight';
import Notification from './Notification';
import Language from './Langauge';
import Brand from './Brand';
import Bookmark from './Bookmark/index';
import { Account, Inbox, LogOut, Taskboard } from '../../Constant';
import profilepic from '../../assets/images/user/user-dp.png';
import { GetUserProfilePicture, HeaderApi, UploadUserProfilePicture, GetUserBrandAccess } from '../../api/index';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setLoginBrands } from '../../Redux/Slices/authSlice';

const HeaderContain = () => {// eslint-disable-next-line
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('');
  useEffect(() => {
    setName(localStorage.getItem('name'));
  }, []);
  const authenticated = JSON.parse(localStorage.getItem('authenticated'));
  const history = useNavigate();
  const Logout = () => {
    localStorage.clear();
    history(`${process.env.PUBLIC_URL}/login`);
  };
  const [brandCounts, setBrandsCounts] = useState(0);
  const dispatch = useDispatch();

  // Fetch profile picture
  const handleView = async () => {
    try {
      const response = await axios.get(GetUserProfilePicture, {
        headers: HeaderApi,
        responseType: 'blob',
      });
      const contentType = response.headers['content-type'];
      const file = new Blob([response.data], { type: contentType });
      const fileURL = URL.createObjectURL(file);
      setProfile(fileURL);
    } catch (error) {
      console.error('Error fetching profile picture:', error);
      toast.error('Profile picture does not exist');
    }
  };

  useEffect(() => {
    handleView();

    const fetchBrands = async () => {
      try {
        const response = await axios.get(GetUserBrandAccess, { headers: HeaderApi });

        if (response.data.status === 1 && response.data.data) {
          setBrandsCounts(response.data.data?.length);
          dispatch(setLoginBrands({ brands: response.data.data, brand_counts: response.data.data?.length }));
        }
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    };

    fetchBrands();

  }, []);


  return (
    <Fragment>
      <div className="nav-right col-10 col-sm-6 pull-right right-header p-0 dash-76">
        <UL attrUL={{ className: `simple-list flex-row nav-menus` }}>
          {/* <LI attrLI={{ className: 'onhover-dropdown' }}><H6 attrH6={{ className: 'txt-dark mb-0 mt-1' }}>EN</H6>
            <Language />
          </LI> */}
          {/* <MoonLight /> */}
          {/* <ItemCart />
          <Bookmark /> */}
          {/* <Notification /> */}
          {brandCounts > 1 ?
            <LI attrLI={{ className: 'onhover-dropdown' }}><H6 attrH6={{ className: 'txt-dark mb-0 mt-1' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30 30"
                width="30"
                height="30"
                fill="currentColor"
              >

                <circle cx="4" cy="4" r="2" />
                <circle cx="12" cy="4" r="2" />
                <circle cx="20" cy="4" r="2" />


                <circle cx="4" cy="12" r="2" />
                <circle cx="12" cy="12" r="2" />
                <circle cx="20" cy="12" r="2" />


                <circle cx="4" cy="20" r="2" />
                <circle cx="12" cy="20" r="2" />
                <circle cx="20" cy="20" r="2" />
              </svg>
            </H6>
              <Brand />
            </LI>
            : null}
          <MaxMiniSize />
          <LI attrLI={{ className: 'profile-nav onhover-dropdown pe-0 pt-0 me-0' }} >
            <Media className="profile-media">
              <Image attrImage={{
                className: 'img-40 rounded-circle', src: `${profile || profilepic}`, alt: '',
              }}
              />
              <Media body>
                <span>{authenticated ? name : ""}</span>
              </Media>
            </Media>
            <UL attrUL={{ className: `simple-list profile-dropdown onhover-show-div` }}>
              {/* <LI><Link to={`${process.env.PUBLIC_URL}/sample-page`}><i><User /></i><span>{Account} </span></Link></LI>
              <LI><Link to={`${process.env.PUBLIC_URL}/sample-page`}><i><Mail /></i><span>{Inbox}</span></Link></LI>
              <LI><Link to={`${process.env.PUBLIC_URL}/sample-page`}><i><FileText /></i><span>{Taskboard}</span></Link></LI> */}
              <LI><Link to={`${process.env.PUBLIC_URL}/edit-profile`}><i><Settings /></i><span>Settings</span></Link></LI>
              <LI attrLI={{ onClick: Logout }}>
                <Link to={`${process.env.PUBLIC_URL}/logout`}>
                  <LogIn /><span>{LogOut}</span>
                </Link>
              </LI>
            </UL>
          </LI>
        </UL>
      </div >
    </Fragment >
  );
};
export default HeaderContain;
