import React, { useState } from 'react';
import { Col, Card, CardFooter, Row, Button, FormGroup, Label, Input, Form, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import Select from "react-select";
import { useSelector } from 'react-redux';
import { ReportData, HeaderApi } from '../api';
import axios from 'axios';

const ReportDataComponent = () => {
    const [formErrors, setFormErrors] = useState({});
    const reportMaster = useSelector(state => state.masters.reportMaster);
    const userID = useSelector(state => state.auth.user.ID);
    const [htmlContent, setHtmlContent] = useState("");
    const [formData, setFormData] = useState({
        report_id: 0,
        user_id: userID,
        start_date: '',
        end_date: ''
    });

    const exportOptions = reportMaster.map(item => ({
        value: item.ID,
        label: `${item.name} - ${item.ID}`,
    }));

    const convertFormData = (data) => ({
        ...data,
        report_id: parseInt(data.report_id, 10),
        user_id: parseInt(userID, 10),
    });

    const formatLocalDateTime = (date) => {
        const pad = (num) => num.toString().padStart(2, '0');
        return `${date.getFullYear()}${pad(date.getMonth() + 1)}${pad(date.getDate())}${pad(date.getHours())}${pad(date.getMinutes())}${pad(date.getSeconds())}`;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateForm();
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            const convertedData = convertFormData(formData);
            try {
                const response = await axios.post(ReportData, convertedData, {
                    headers: HeaderApi,
                });

                if (response.data.status === 1) {
                    setHtmlContent(response.data.data);
                    toast.success("Report generated successfully", { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
                }
            } catch (error) {
                console.error('Export error:', error);
                toast.error(error.response?.data?.error || 'Error occurred during report', { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
                if (error.response?.status === 401) {
                    localStorage.clear();
                    window.location.reload();
                }
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleReportChange = (selectedOption) => {
        setFormData(prevData => ({ ...prevData, report_id: selectedOption ? selectedOption.value : 0 }));
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.report_id) errors.report_id = "Export is required";
        if (!formData.start_date) errors.start_date = "Start date is required";
        if (!formData.end_date) errors.end_date = "End date is required";

        if (formData.start_date && formData.end_date) {
            const startDate = new Date(formData.start_date);
            const endDate = new Date(formData.end_date);
            if (endDate < startDate) {
                errors.end_date = "End date cannot be earlier than the start date";
            }
        }

        return errors;
    };

    const handleClear = () => {
        setFormData({
            report_id: 0,
            user_id: userID,
            start_date: '',
            end_date: ''
        });
        setHtmlContent("");
        setFormErrors({});
    };

    return (
        <Col sm="12">
            <Form onSubmit={handleSubmit}>
                <Card>
                    <Row className="card-block justify-content-center align-items-center">
                        <Col sm="12" className="d-flex justify-content-center align-items-center">
                            <h2 className="text-center">Report</h2>
                        </Col>
                        <Col xl="8">
                            <Card className="d-flex justify-content-center" xl={6}>
                                <FormGroup row>
                                    <Col sm={12}>
                                        <Form>
                                            <CardBody>
                                                <Label for="exportSelect">Select Report</Label>
                                                <Col sm={10}>
                                                    <Select
                                                        className="basic-single"
                                                        value={exportOptions.find(option => option.value === formData.report_id)}
                                                        isSearchable={true}
                                                        name="report_id"
                                                        id="exportSelect"
                                                        options={exportOptions}
                                                        onChange={handleReportChange}
                                                    />
                                                    {formErrors.report_id && <div className="text-danger">{formErrors.report_id}</div>}
                                                </Col>
                                            </CardBody>
                                        </Form>
                                    </Col>
                                    <Col sm={6}>
                                        <Form>
                                            <CardBody>
                                                <Label for="start_date">Start Date</Label>
                                                <Col sm={10}>
                                                    <Input
                                                        id="start_date"
                                                        type="date"
                                                        name="start_date"
                                                        onChange={handleInputChange}
                                                        value={formData.start_date}
                                                    />
                                                    {formErrors.start_date && <div className="text-danger">{formErrors.start_date}</div>}
                                                </Col>
                                            </CardBody>
                                        </Form>
                                    </Col>
                                    <Col sm={6}>
                                        <Form>
                                            <CardBody>
                                                <Label for="end_date">End Date</Label>
                                                <Col sm={10}>
                                                    <Input
                                                        id="end_date"
                                                        type="date"
                                                        name="end_date"
                                                        onChange={handleInputChange}
                                                        value={formData.end_date}
                                                    />
                                                    {formErrors.end_date && <div className="text-danger">{formErrors.end_date}</div>}
                                                </Col>
                                            </CardBody>
                                        </Form>
                                    </Col>
                                </FormGroup>
                            </Card>
                            <CardFooter className="text-center">
                                <Button type="submit" color="btn btn-outline-info">Generate Report</Button>
                                <Button color="btn btn-outline-danger" onClick={handleClear} style={{ marginLeft: 10 }}>Clear</Button>
                            </CardFooter>
                        </Col>

                        {htmlContent ? (
                            <Col xl="11">
                                <CardFooter className="text-center">
                                    <div
                                        dangerouslySetInnerHTML={{ __html: htmlContent }}
                                    />
                                </CardFooter>
                            </Col>
                        ) : null}

                    </Row>
                </Card>
            </Form>
        </Col>
    );
};

export default ReportDataComponent;
