import React, { useState, useEffect, useCallback } from 'react';
import { GetAllUsers, HeaderApi } from '../../../api';
import { Row, Col, Input, Button, Card, FormGroup, CardFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import { formatDateTime } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';

const List = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [users, setUsers] = useState([]);

    const fetchUserData = useCallback(async () => {
        const requestOptions = {
            method: 'POST',
            headers: HeaderApi
        };

        try {
            const response = await axios(GetAllUsers, requestOptions);
            if (Array.isArray(response.data.data)) {
                setUsers(response.data.data);
            } else {
                console.error('Expected an array but got:', typeof response.data.data);
                setUsers([]);
            }
        } catch (error) {
            console.error('Error with the Axios request:', error);
            toast.error(error.response?.data?.error || 'An error occurred', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000
            });
            if (error.response?.status === 401) {
                localStorage.clear();
                window.location.reload();
            }
            setUsers([]);
        }
    }, []);

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);

    const navigate = useNavigate();
    const navigateToAddUser = () => navigate(`${process.env.PUBLIC_URL}/ums/add-user`);
    const navigateToEditUser = (id) => navigate(`${process.env.PUBLIC_URL}/ums/edit-user/${id}`);
    const navigateToChangePassword = (id) => navigate(`${process.env.PUBLIC_URL}/ums/password-management/${id}`);

    const handleSearchChange = (e) => setSearchTerm(e.target.value);

    const getFilteredUsers = () => {
        return users.filter((user) =>
            user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.mobile.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            (user.user_status_id === 1 ? "Active" : "Closed").toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    const filteredUsers = searchTerm ? getFilteredUsers() : users;

    // Define table columns for DataTable
    const columns = [
        {
            name: '#',
            selector: (row) => row.ID,
            sortable: true,
            width: '80px',
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: 'Mobile',
            selector: (row) => row.mobile,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row) => (row.user_status_id === 1 ? "Active" : "Closed"),
            sortable: true,
        },
        {
            name: 'Last Login',
            selector: (row) => (row.user_last_login_datetime ? formatDateTime(row.user_last_login_datetime) : "-"),
            sortable: true,
        },
        {
            name: 'Created On',
            selector: (row) => (row.CreatedAt ? formatDateTime(row.CreatedAt) : "-"),
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row) => (
                <>
                    <Button
                        className="btn btn-primary btn-sm"
                        onClick={() => navigateToEditUser(row.ID)}
                    >
                        <i className="icofont icofont-edit"></i>
                    </Button>
                    <Button
                        className="btn btn-info btn-sm"
                        style={{ marginLeft: '5px' }}
                        onClick={() => navigateToChangePassword(row.ID)}
                    >
                        <i className="icofont icofont-key"></i>
                    </Button>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return (
        <Col xl="12">
            <Card>
                <Col sm="12" className="d-flex justify-content-center align-items-center">
                    &nbsp;
                </Col>
                <Col sm="12" className="d-flex justify-content-center align-items-center">
                    <h2 className="text-center">User List</h2>
                </Col>
                <Row>
                    <Col sm="12" className="d-flex justify-content-center align-items-center">
                        <FormGroup className="d-flex justify-content-center align-items-center" style={{ width: '100%', margin: '1rem' }}>
                            <Col md={4}>
                                <Input
                                    type="text"
                                    placeholder="Type To Search"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                            </Col>
                            <Col md={2} style={{ marginLeft: "10px" }} className="d-flex justify-content-start p-0">
                                <Button color="btn-pill btn btn-success" style={{ marginLeft: 5 }} onClick={navigateToAddUser}>ADD USER</Button>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>

                <CardFooter>
                    <DataTable
                        columns={columns}
                        data={filteredUsers}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[5, 10, 20, 30, 50]}
                        pagination
                        highlightOnHover
                        pointerOnHover
                        responsive
                        striped
                    />
                </CardFooter>
            </Card>
        </Col>
    );
};

export default List;
