import React, { useState, useEffect, useMemo } from 'react';
import { Col, Card, Row, FormGroup } from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { HeaderApi, GetUserActivityLog, GetAllUsers } from '../../../api';
import Select from "react-select";
import { formatDateTime } from '../../../utils';
import DataTable from 'react-data-table-component';

const AssignRoles = () => {
    const [users, setUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [logs, setLogs] = useState([]);
    const [logCount, setLogCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.post(GetAllUsers, undefined, { headers: HeaderApi });
                const userdata = response.data.data.map(user => ({
                    label: `${user.name} - ${user.email}`.toUpperCase(),
                    value: user.ID
                }));
                setUsers(userdata);
            } catch (error) {
                toast.error("Failed to fetch users: " + error, { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
                if (error.response?.status === 401) {
                    localStorage.clear();
                    window.location.reload();
                }
            }
        };
        fetchUsers();
    }, []);

    useEffect(() => {
        const fetchLogs = async () => {
            if (!selectedUserId) return;
            try {
                const response = await axios.post(GetUserActivityLog, {
                    user_id: parseInt(selectedUserId, 10),
                    limit: rowsPerPage,
                    offset: (currentPage - 1) * rowsPerPage
                }, { headers: HeaderApi });
                setLogs(response.data.data || []);
                setLogCount(response.data.total_count || 0);
            } catch (error) {
                toast.error("Error fetching logs: " + error, { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
                if (error.response?.status === 401) {
                    localStorage.clear();
                    window.location.reload();
                }
            }
        };
        fetchLogs();
    }, [selectedUserId, currentPage]);

    const handleUserChange = (selectedOption) => {
        setSelectedUserId(selectedOption ? selectedOption.value : '');
        setCurrentPage(1); // Reset to the first page when a new user is selected
    };

    const columns = [
        {
            name: 'Log ID',
            selector: row => row.ID,
            sortable: true,
        },
        {
            name: 'DateTime',
            selector: row => row.CreatedAt ? formatDateTime(row.CreatedAt) : '-',
            sortable: true,
        },
        {
            name: 'URL',
            selector: row => row.url,
        },
        {
            name: 'Platform',
            selector: row => row.platform,
        },
        {
            name: 'Browser',
            selector: row => row.browser,
        },
        {
            name: 'Activity',
            selector: row => (row.type_id === 1 ? 'LOGIN' : 'LOGOUT'),
        },
        {
            name: 'Source',
            selector: row => row.source_type === 1 ? 'WEB' : row.source_type === 2 ? 'MOBILE' : 'NA',
        },
    ];

    const handlePageChange = page => setCurrentPage(page);

    return (
        <Col sm="12">
            <Card>
                <Row className="card-block justify-content-center align-items-center">
                    <Col sm="12" className="d-flex justify-content-center align-items-center">
                        <h2 className="text-center">User Activity Logs</h2>
                    </Col>
                    <Col sm="12" className="d-flex justify-content-center align-items-center">
                        <FormGroup className="d-flex justify-content-center align-items-center" style={{ width: '100%', margin: '1rem' }}>
                            <Col md={4}>
                                <Select
                                    className="basic-single"
                                    value={users.find(user => user.value === selectedUserId)}
                                    isSearchable={true}
                                    name="user_id"
                                    options={users}
                                    onChange={handleUserChange}
                                />
                            </Col>
                        </FormGroup>
                    </Col>

                    <Col xs="12">
                        <DataTable
                            columns={columns}
                            data={logs}
                            pagination
                            paginationServer
                            paginationTotalRows={logCount}
                            paginationPerPage={rowsPerPage}
                            onChangePage={handlePageChange}
                            highlightOnHover
                            pointerOnHover
                            responsive
                            striped
                            noDataComponent={<div style={{ color: 'red', fontSize: 15 }}>No records found</div>}
                        />
                    </Col>
                </Row>
            </Card>
        </Col>
    );
};

export default AssignRoles;
