const api = `https://api.finclouds.tech`;
// const api = `http://localhost:8080`;

export const HeaderApi = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
    'X-CSRF-Token': localStorage.getItem('csrf_token'),
    'X-ID': localStorage.getItem('user_id'),
    'X-DEBUG': false,
};


export const GetStateList = `${api}/api/v1/get-state-list`;
export const GetCityListByStateId = `${api}/api/v1/get-city-by-state-id`;
export const GetPincodeListByCityId = `${api}/api/v1/get-pincode-by-city-id`;
export const GetReligionList = `${api}/api/v1/get-religion-list`;
export const GetDocumentsMasterList = `${api}/api/v1/get-document-master-list`;
export const UploadDocuments = `${api}/api/v1/upload-documents`;
export const GetProfilePicture = `${api}/api/v1/profile-picture/`;
export const ViewDocuments = `${api}/api/v1/view-documents/`;
export const DeleteDocument = `${api}/api/v1/delete-documents/`;
export const GetLeadDetailsList = `${api}/api/v1/get-lead-details`;
export const GetLeadDetails = `${api}/api/v1/lead-details/`;
export const GetDocsDetails = `${api}/api/v1/documents-details/`;
export const GetEmploymentDetails = `${api}/api/v1/employment-details/`;
export const UpdateApplicationDetails = `${api}/api/v1/update-application-details/`;
export const GetPersonalDetails = `${api}/api/v1/personal-details/`;
export const UpdatePersonalDetails = `${api}/api/v1/personal-details/`;
export const GetBankingDetails = `${api}/api/v1/banking-details/`;
export const GetVerificationDetails = `${api}/api/v1/verification-details/`;
export const GetCAMDetails = `${api}/api/v1/get-cam-details/`;
export const GetDisbursalDetails = `${api}/api/v1/disbursal-details/`;
export const LeadAllocation = `${api}/api/v1/allocate-leads`;
export const LeadRecommend = `${api}/api/v1/lead-recommended`;
export const ApplicationRecommend = `${api}/api/v1/application-recommended`;
export const ApplicationLogs = `${api}/api/v1/application-log-history`;
export const GetOccupationsList = `${api}/api/v1/get-occupations-list`;
export const GetWorkModeList = `${api}/api/v1/get-work-mode-list`;
export const GetIndustryList = `${api}/api/v1/get-industry-list`;
export const GetSalaryModeList = `${api}/api/v1/get-salary-mode-list`;
export const GetEmployerTypeList = `${api}/api/v1/get-employment-type-list`;
export const GetDesignationList = `${api}/api/v1/get-designation-list`;
export const GetDepartmentList = `${api}/api/v1/get-department-list`;
export const GetResidenceTypeList = `${api}/api/v1/get-residence-list`;
export const GetRejectionList = `${api}/api/v1/get-rejection-reason-list`;
export const GetExportMasterList = `${api}/api/v1/get-export-list`;
export const GetReportMasterList = `${api}/api/v1/get-report-list`;
export const SaveCAMDetails = `${api}/api/v1/save-cam-details`;
export const GetBankVerificationStatusList = `${api}/api/v1/get-bank-verification-status-list`;
export const GetAccountTypeList = `${api}/api/v1/get-account-type-list`;
export const SearchIFSC = `${api}/api/v1/search-ifsc-code`;
export const SaveBankingDetails = `${api}/api/v1/save-banking-details`;
export const LeadReject = `${api}/api/v1/lead-reject`;
export const ApplicationSendBack = `${api}/api/v1/application-send-back`;
export const SanctionLead = `${api}/api/v1/sanction-lead`;
export const DisbursalSendBack = `${api}/api/v1/disbursal-send-back`;
export const DisbursalRecommend = `${api}/api/v1/disbursal-recommend`;
export const DisbursalPending = `${api}/api/v1/disbursal-pending`;
export const Disbursed = `${api}/api/v1/disburse`;
export const GetLoanDetails = `${api}/api/v1/get-loan-details/`;
export const GetPaymentModeList = `${api}/api/v1/get-payment-mode-types-list`;
export const SaveRepaymentData = `${api}/api/v1/save-payment-data`;
export const GetAllCollections = `${api}/api/v1/get-all-collections/`;
export const GetCollection = `${api}/api/v1/get-collection/`;
export const VerifyPaymnet = `${api}/api/v1/verify-payment`;
export const DeleteRepaymentData = `${api}/api/v1/delete-collection-details`;
export const InternalDedupe = `${api}/api/v1/internal-dedupe`;

// Admin
export const ImportLeads = `${api}/api/v1/import-leads`;
export const ExportData = `${api}/api/v1/export-data`;
export const ReportData = `${api}/api/v1/report-data`;
export const GetMasterBrand = `${api}/api/v1/get-brands-list`;





// UMS
export const LoginApi = `${api}/api/v1/user/login`;
export const IsValidUser = `${api}/api/v1/user/valid-user`;
export const MenuListApi = `${api}/api/v1/user/getmenu-list`;
export const ChangePassword = `${api}/api/v1/user/change-password`;
export const ForgotPassword = `${api}/api/v1/user/forgot-password`;
export const GetAllUsers = `${api}/api/v1/user/get-all-users`;
export const CreateUser = `${api}/api/v1/user/create-user`;
export const GetUser = `${api}/api/v1/user/get-user/`;
export const UpdateUser = `${api}/api/v1/user/update-user/`;
export const AddRoles = `${api}/api/v1/user/update-user/`;
export const RolesList = `${api}/api/v1/user/get-role-list`;
export const RolesListByUserId = `${api}/api/v1/user/get-role-list/`;
export const UpdateRole = `${api}/api/v1/user/update-roles`;
export const GetUserActivityLog = `${api}/api/v1/user/get-activity-logs`;
export const MainSearch = `${api}/api/v1/search`;
export const SendNocLetter = `${api}/api/v1/send-closed-noc`;
export const NocLogUrl = `${api}/api/v1/get-noc-letter-log/`;
export const UploadUserProfilePicture = `${api}/api/v1/user/upload-profile-picture`;
export const GetUserProfilePicture = `${api}/api/v1/user/profile-picture`;
export const GetUserBrandAccess = `${api}/api/v1/user/get-brand-access-list`;
export const SwitchBrandAccess = `${api}/api/v1/user/switch-brand-access`;



// Collection
export const GetCollectionDetails = `${api}/api/v1/get-collection-details/`;

// Dashboard
export const GetDashboardDefault = `${api}/api/v1/dashboard/default`;

// export const TaskApi = `${api}/task.json`;
// export const BookmarkApi = `${api}/bookmark.json`;
// export const ChartistApi = `${api}/chartistData.json`;
// export const ChartjsApi = `${api}/chartjsData.json`;
// export const ChatMemberApi = `${api}/chatMember.json`;
// export const ChatApi = `${api}/chats.json`;
// export const ContactApi = `${api}/contacts.json`;
// export const ProductsApi = `${api}/product.json`;
// export const EmailApi = `${api}/email.json`;
// export const ImageLightApi = `${api}/image-light.json`;
// export const BigImageApi = `${api}/image-big-light.json`;
// export const MasonryApi = `${api}/masonry.json`;
// export const GooglechartApi = `${api}/googleChartData.json`;
// export const AllProjectApi = `${api}/allProject.json`;
// export const SearchAllTabsApi = `${api}/searchAllTabs.json`;
// export const TableDataApi = `${api}/tableData.json`;
// export const TodoApi = `${api}/todo.json`;
// export const JobSearchApi = `${api}/jobSearch.json`;
// export const LearningApi = `${api}/learning.json`;
// export const KnowledgebaseApi = `${api}/knowledgebaseDB.json`;
// export const FaqApi = `${api}/Faq.json`;
// export const FileApi = `${api}/files.json`;
// export const UserCardApi = `${api}/usercard.json`;
// export const StickyApi = `${api}/sticky.json`;
// export const TypeaheadDataApi = `${api}/typeaheadData.json`;
// export const FeatherDataApi = `${api}/featherData.json`;
// export const FlagIconDataApi = `${api}/flagIconData.json`;
// export const FontawsomeDataApi = `${api}/fontawsomeData.json`;
// export const IcoDataApi = `${api}/icoData.json`;
// export const ThemifyDataApi = `${api}/themifyData.json`;
