import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Col, Card, CardHeader, Row, Table, Button, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { HeaderApi, GetLeadDetailsList, LeadAllocation } from '../../api/index';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Spinner } from '../../AbstractElements';
import { mask_mobile, mask_email, formatDateTime, formatDate, arraysHaveAnyCommonElements } from '../../utils';
import { useSelector } from 'react-redux';

const rowsPerPage = 15;

const LeadList = (props) => {
  const [data, setData] = useState([]);
  const [leadCount, setLeadCount] = useState(rowsPerPage);
  const [leadDataCount, setLeadDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState({});
  const lead_status_id = parseInt(props.props.lead_status_id, 10);
  const title = props.props.title;
  const label = props.props.label;
  const [allocation_on, setAllocation_on] = useState(false);
  let leadStatusList = [];
  const roleIds = useSelector(state => state.auth.roles);
  const [maskingFlag, setMaskingFlag] = useState(true);
  const userID = useSelector(state => state.auth.userId);

  const fetchLeadData = useCallback(async () => {
    const requestOptions = {
      method: 'POST',
      headers: HeaderApi,
      data: { lead_status_id: lead_status_id, label: label, start: (currentPage - 1) * rowsPerPage, limit: rowsPerPage }
    };

    try {
      const response = await axios(GetLeadDetailsList, requestOptions);
      if (Array.isArray(response.data.data)) {
        setData(response.data.data);
        setLeadCount(response.data.total_count);
        setLeadDataCount(response.data.total_count);

        if (arraysHaveAnyCommonElements(roleIds, [3, 4]) && [4, 5, 7, 8, 9].includes(lead_status_id)) {
          setMaskingFlag(false);
        } else if (arraysHaveAnyCommonElements(roleIds, [1, 2, 14, 15, 16])) {
          setMaskingFlag(false);
        }

      } else {
        console.error('Expected an array but got:', typeof response.data.data);
        setData([]);
      }
    } catch (error) {
      console.error('Error with the Axios request:', error);
      toast.error(error.response.data.error, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      setData([]);
      setLeadCount(10);
    }
  }, [currentPage, lead_status_id]);

  useEffect(() => {
    fetchLeadData();
  }, [fetchLeadData]);

  const handleRowSelect = useCallback((id) => {
    setSelectedRows(prev => ({ ...prev, [id]: !prev[id] }));
  }, []);

  const handleSelectAll = useCallback(() => {
    const newSelections = {};
    data.forEach(item => {
      newSelections[item.id] = selectedRows[item.id] ? !selectedRows[item.id] : true;
    });
    setSelectedRows(newSelections);
  }, [data, selectedRows]);


  const navigate = useNavigate();
  const navigateToLeadDetails = (id) => {
    navigate(`${process.env.PUBLIC_URL}/lead-details/${id}/${lead_status_id}`);
  };

  const { canGoBack, canGoForward, totalPages, startPage, endPage } = useMemo(() => {
    const totalPages = Math.ceil(leadCount / rowsPerPage);
    const canGoBack = currentPage > 1;
    const canGoForward = currentPage < totalPages;
    const maxPagesToShow = 5;
    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = startPage + maxPagesToShow - 1;


    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }

    return { canGoBack, canGoForward, totalPages, startPage, endPage };
  }, [currentPage, leadCount]);

  const allocated = () => {
    let selected = [];
    Object.keys(selectedRows).forEach((key) => {
      if (selectedRows[key]) {
        selected.push(key);
      }
    });

    if (selected.length === 0) {
      toast.error("Please select atleast one lead", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000

      });
      return;
    }

    const requestOptions = {
      method: 'POST',
      headers: HeaderApi,
      data: { lead_status_id: lead_status_id, lead_list: selected }
    };

    axios(LeadAllocation, requestOptions).then((response) => {
      if (response.data.status === 1) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000
        });
        fetchLeadData();
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000
        });
      }
    }).catch((error) => {
      console.error('Error with the Axios request:', error);
      toast.error(error.response.data.error, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      if (error.response.status === 401) {

        localStorage.clear();
        window.location.reload();
      }
    });
  };

  // push the data in array and check the data is present or not

  useEffect(() => {
    leadStatusList = [3, 6, 15];
    if (leadStatusList.includes(lead_status_id) && leadDataCount > 0) {
      setAllocation_on(true);
    }
  }, [lead_status_id, leadDataCount]);


  return (
    <Col sm="12">
      <Card>
        <CardHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div className="loader-box" style={{ height: 0 }}>
            <h3 className="text-center" style={{ marginTop: '1rem' }}>{title}</h3>&nbsp;&nbsp;
            {allocation_on && (
              <Button className="float-left" onClick={allocated} color="primary">
                Allocate
              </Button>
            )}
          </div>
          {leadDataCount > 0 ?
            <div className="loader-box" style={{ height: 0 }}>
              <Spinner attrSpinner={{ className: 'loader-22', style: { fontSize: '10px' } }} text={leadDataCount} />&nbsp;
            </div>
            : null}
        </CardHeader>
        <Row className="card-block">
          <Col sm="12" lg="12" xl="12">
            <div className="table-responsive">
              <Table hover striped>
                <thead className="table-success" style={{ backgroundColor: "red" }}>
                  <tr>
                    {allocation_on ?
                      <th scope="col">
                        <input type="checkbox"
                          onChange={handleSelectAll}
                          checked={Object.keys(selectedRows).length === leadDataCount && leadDataCount > 0}
                          indeterminate={Object.keys(selectedRows).some(id => selectedRows[id]) && !data.every(item => selectedRows[item.id])}
                        />
                      </th>
                      :
                      <th scope="col">Action</th>}
                    <th scope="col">{'#'}</th>
                    <th scope="col">{(label === 'pre-collection' || label === 'collection-pending' || label === 'recovery-pending' || label === 'pre-closure' || label === 'closure') ? 'Repayment Date' : 'Applied On'}</th>
                    <th scope="col">{'Name'}</th>
                    <th scope="col">{'Pan Card'}</th>
                    <th scope="col">{'Mobile'}</th>
                    <th scope="col">{'Email'}</th>
                    <th scope="col">{'Loan Amount'}</th>
                    <th scope="col">{'User Type'}</th>
                  </tr>
                </thead>
                <tbody>

                  {leadDataCount > 0 ? data.map((item) => (
                    <tr key={item.id} style={{ textAlign: "center" }}>
                      {allocation_on ?
                        <td>
                          <input type="checkbox"
                            checked={!!selectedRows[item.id]}
                            onChange={() => handleRowSelect(item.id)} />
                        </td>
                        :
                        <Button onClick={() => navigateToLeadDetails(item.id)} color=""><i className="fa fa-eye" style={{ fontSize: "22px", padding: "0px 13px 0px 13px" }}></i></Button>
                      }
                      <th scope="row">{item.id}</th>
                      <td>{(label === 'pre-collection' || label === 'collection-pending' || label === 'recovery-pending' || label === 'pre-closure' || label === 'closure') ? formatDate(item.repayment_date) : formatDateTime(item.created_at)}</td>
                      <td>{item.cust_full_name}</td>
                      <td>{item.pancard}</td>
                      <td>{maskingFlag ? mask_mobile(item.mobile) : item.mobile}</td>
                      <td>{maskingFlag ? mask_email(item.email) : item.email}</td>
                      <td>{(label === 'pre-collection' || label === 'collection-pending' || label === 'recovery-pending' || label === 'pre-closure' || label === 'closure') ? item.loan_recommended : item.applied_amount}</td>
                      <td>{item.user_type}</td>
                    </tr>
                  )) : (
                    <tr>
                      <td colSpan="10" align={'center'} style={{ color: "#f40606" }}>No data available</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
        {leadDataCount > 0 ?
          <Row>
            <Col className="d-flex justify-content-end">
              <Pagination aria-label="Page navigation example">
                <PaginationItem disabled={!canGoBack}>
                  <PaginationLink onClick={() => setCurrentPage(1)} >First Page</PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={!canGoBack}>
                  <PaginationLink onClick={() => setCurrentPage(c => c - 1)} previous />
                </PaginationItem>
                {Array.from({ length: (endPage - startPage) + 1 }, (_, i) => (
                  <PaginationItem key={startPage + i} active={startPage + i === currentPage}>
                    <PaginationLink onClick={() => setCurrentPage(startPage + i)}>
                      {startPage + i}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={!canGoForward}>
                  <PaginationLink onClick={() => setCurrentPage(c => c + 1)} next />
                </PaginationItem>
                <PaginationItem disabled={!canGoForward}>
                  <PaginationLink onClick={() => setCurrentPage(totalPages)} >Last Page</PaginationLink>
                </PaginationItem>
              </Pagination>
            </Col>
          </Row> : null}
      </Card>
    </Col >
  );
};

export default LeadList;
