import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userId: null,
    name: null,
    token: null,
    roles: [],
    user: {},
    isAuthenticated: false,
    brands: [],
    brands_counts: 0,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess: (state, action) => {
            state.user = action.payload.user;
            state.name = action.payload.name;
            state.token = action.payload.token;
            state.roles = action.payload.roles;
            state.userId = action.payload.userId;
            state.isAuthenticated = true;
        },

        logout: (state) => {
            state.userId = null;
            state.name = null;
            state.token = null;
            state.roles = [];
            state.user = {};
            state.isAuthenticated = true;
        },

        setLoginBrands: (state, action) => {
            state.brands = action.payload.brands;
            state.brands_counts = action.payload.brands_counts;
        },
    },
});

export const { loginSuccess, logout, setLoginBrands } = authSlice.actions;
export default authSlice.reducer;
