import React, { Fragment, useState, useEffect } from 'react';
import { LI, UL } from '../../AbstractElements';
import axios from 'axios';
import { GetUserBrandAccess, HeaderApi, SwitchBrandAccess } from '../../api';
import { useSelector, useDispatch } from 'react-redux';
import { loginSuccess, setLoginBrands } from '../../Redux/Slices/authSlice';
import { toast } from 'react-toastify';

const BrandChange = () => {
    const [selectedBrand, setSelectedBrand] = useState(useSelector((state) => state.auth.user?.brand_id) || null);
    // const [brands, setBrands] = useState([]);
    const userID = useSelector((state) => state.auth.userId);
    const userRoles = useSelector((state) => state.auth.roles);
    const brands = useSelector((state) => state.auth.brands);
    const dispatch = useDispatch();

    /*useEffect(() => {
        const fetchBrands = async () => {
            try {
                const response = await axios.get(GetUserBrandAccess, { headers: HeaderApi });

                if (response.data.status === 1 && response.data.data) {
                    setBrands(response.data.data);
                    dispatch(setLoginBrands({ brands: response.data.data, brand_counts: response.data.data?.length }));
                } else {
                    setBrands([]);
                }
            } catch (error) {
                console.error('Error fetching brands:', error);
                setBrands([]); // Handle errors gracefully
            }
        };

        fetchBrands();
    }, []);*/

    const changeBrand = async (brandId, brandName) => {
        const confirmSwitch = window.confirm(`Are you sure you want to switch to "${brandName}"?`);
        if (confirmSwitch) {

            try {
                const response = await axios.put(SwitchBrandAccess, {
                    user_id: userID,
                    brand_id: brandId
                }, { headers: HeaderApi });

                let userData = response.data.data;

                if (response.data.status === 1) {
                    toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
                    setSelectedBrand(brandId);

                    dispatch(loginSuccess({ token: userData?.access_token, user: userData, roles: userRoles, userId: userData?.ID, name: userData?.name }));
                    window.location.href = '/dashboard';
                } else {
                    toast.error(response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
                }
            } catch (error) {
                toast.error(error.response?.data?.error || 'Error occurred', { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
                if (error.response?.status === 401) {
                    localStorage.clear();
                    window.location.reload();
                }
            }
        }
    };

    return (
        <Fragment>
            <div
                className="brand-dropdown onhover-show-div"
                style={{ maxHeight: '200px', maxWidth: '200px', overflowY: 'auto', border: '1px solid #ccc', padding: '10px' }}
            >
                <UL style={{ margin: 0, padding: 0, listStyleType: 'none' }}>
                    {brands.length > 0 ? (
                        brands.map((brand) => (
                            <LI
                                key={brand.id}
                                attrLI={{ onClick: () => changeBrand(brand.brand?.ID, brand.brand?.name) }}
                                style={{
                                    margin: '4px 0',
                                    padding: '6px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    borderRadius: '3px',
                                    transition: 'background-color 0.2s ease',
                                    backgroundColor: selectedBrand === brand.brand?.ID ? '#f0f0f0' : 'transparent',
                                }}
                                onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#f5f5f5')}
                                onMouseOut={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                    selectedBrand === brand.brand?.ID ? '#f0f0f0' : 'transparent')
                                }
                            >
                                <img
                                    src={brand.brand?.fav_icon || 'https://via.placeholder.com/20'} // Default logo if missing
                                    alt="Logo"
                                    style={{ width: '20px', height: '20px', marginRight: '8px' }}
                                />
                                <span style={{ fontSize: '14px', color: '#333' }}>{brand.brand?.name}</span>
                            </LI>
                        ))
                    ) : (
                        <LI style={{ padding: '10px', textAlign: 'center', color: '#999' }}>No Entity available</LI>
                    )}
                </UL>
            </div>
        </Fragment>
    );
};

export default BrandChange;
