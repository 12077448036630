import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Form, FormGroup, Input, Label, Media, Row } from 'reactstrap';
import { Btn, H3, H4, P } from '../../../AbstractElements';
import { MyProfile, Bio, Password, Website, Save, EmailAddress, ConfirmPassword } from '../../../Constant';
import user from '../../../assets/images/user/7.jpg';
import { GetUserProfilePicture, HeaderApi, UploadUserProfilePicture } from '../../../api/index';
import axios from 'axios';
import { toast } from 'react-toastify';

const MyProfileEdit = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [designation, setDesignation] = useState('');
  const [profilePicture, setProfilePicture] = useState(user);
  const [file, setFile] = useState(null);

  // Load user data on mount
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user_data'));
    if (userData) {
      setName(userData.name);
      setEmail(userData.email);
      setPhone(userData.phone);
      setAddress(userData.address);
      setDesignation(userData.designation);
    }
  }, []);

  // Fetch profile picture
  const handleView = async () => {
    try {
      const response = await axios.get(GetUserProfilePicture, {
        headers: HeaderApi,
        responseType: 'blob',
      });
      const contentType = response.headers['content-type'];
      const file = new Blob([response.data], { type: contentType });
      const fileURL = URL.createObjectURL(file);
      setProfilePicture(fileURL);
    } catch (error) {
      console.error('Error fetching profile picture:', error);
      toast.error('Profile picture does not exist');
    }
  };

  useEffect(() => {
    handleView();
  }, []);

  // Handle file selection
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Upload profile picture
  const handleUpload = async () => {
    if (!file) {
      toast.error('Please select a file to upload');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await axios.post(UploadUserProfilePicture, formData, {
        headers: {
          ...HeaderApi,
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Profile picture updated successfully');
      handleView();
      window.location.reload();
    } catch (error) {
      toast.error(error.response?.data?.error || 'Failed to upload profile picture');
    }
  };

  return (
    <Fragment>
      <Card>
        <CardHeader className="pb-0">
          <H4 attrH4={{ className: 'card-title mb-0' }}>{MyProfile}</H4>
          <div className="card-options">
            <a className="card-options-collapse" href="#javascript">
              <i className="fe fe-chevron-up"></i>
            </a>
            <a className="card-options-remove" href="#javascript">
              <i className="fe fe-x"></i>
            </a>
          </div>
        </CardHeader>
        <CardBody>
          <Form>
            <Row className="mb-2">
              <div className="profile-title">
                <Media>
                  <img
                    className="img-70 rounded-circle"
                    alt="Profile"
                    src={profilePicture || user}
                  />
                  <Media body>
                    <H3 attrH3={{ className: 'mb-1 f-20 txt-primary' }}>{name}</H3>
                    <P>{designation}</P>
                  </Media>
                </Media>
              </div>
            </Row>
            <FormGroup>
              <Label for="uploadProfilePicture">Upload Profile Picture</Label>
              <Input
                type="file"
                id="uploadProfilePicture"
                onChange={handleFileChange}
                className="form-control"
              />
            </FormGroup>
            <div className="form-footer mb-3">
              <Btn
                attrBtn={{
                  className: 'btn-block',
                  color: 'primary',
                  type: 'button',
                  onClick: handleUpload,
                }}
              >
                Upload
              </Btn>
            </div>
            <FormGroup className="mb-3">
              <Label className="form-label">{EmailAddress}</Label>
              <Input className="form-control" placeholder={email} disabled />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label className="form-label">{Password}</Label>
              <Input className="form-control" type="password" defaultValue="" />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label className="form-label">{ConfirmPassword}</Label>
              <Input className="form-control" type="password" defaultValue="" />
            </FormGroup>
            <div className="form-footer">
              <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'button' }}>
                {Save}
              </Btn>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default MyProfileEdit;
