import React, { useState } from 'react';
import { Col, Card, CardFooter, Row, Button, FormGroup, Label, Input, Form, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import Select from "react-select";
import { useSelector } from 'react-redux';
import { ExportData, HeaderApi } from '../api';
import axios from 'axios';

const ExportDataComponent = () => {
    const [formErrors, setFormErrors] = useState({});
    const exportMaster = useSelector(state => state.masters.exportMaster);
    const userID = useSelector(state => state.auth.user.ID);
    const [formData, setFormData] = useState({
        export_id: 0,
        user_id: userID,
        start_date: '',
        end_date: ''
    });

    const exportOptions = exportMaster.map(item => ({
        value: item.ID,
        label: `${item.name} - ${item.ID}`,
    }));

    const convertFormData = (data) => ({
        ...data,
        export_id: parseInt(data.export_id, 10),
        user_id: parseInt(userID, 10),
    });

    const formatLocalDateTime = (date) => {
        const pad = (num) => num.toString().padStart(2, '0');
        return `${date.getFullYear()}${pad(date.getMonth() + 1)}${pad(date.getDate())}${pad(date.getHours())}${pad(date.getMinutes())}${pad(date.getSeconds())}`;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateForm();
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            const convertedData = convertFormData(formData);
            try {
                const response = await axios.post(ExportData, convertedData, {
                    headers: HeaderApi,
                    responseType: 'blob'
                });

                if (response.data.status === 0) {
                    toast.error(response.data.message || 'No data available for export', { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
                    return;
                }

                // Check if the response is JSON (likely an error message)
                const contentType = response.headers['content-type'];
                if (contentType && contentType.includes('application/json')) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const result = JSON.parse(reader.result);
                        if (result.status === 0) {
                            toast.error(result.message || 'No data available for export', { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
                        }
                    };
                    reader.readAsText(response.data);
                } else {
                    // Proceed with file download
                    const exportName = exportMaster.find(item => item.ID === formData.export_id).name;
                    const currentDateTime = formatLocalDateTime(new Date());
                    const blob = new Blob([response.data], { type: response.headers['content-type'] });

                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${exportName}_${formData.start_date}_${formData.end_date}_${currentDateTime}.xlsx`;
                    link.click();

                    toast.success("Data exported successfully", { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
                }
            } catch (error) {
                console.error('Export error:', error);
                toast.error(error.response?.data?.error || 'Error occurred during export', { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
                if (error.response?.status === 401) {
                    localStorage.clear();
                    window.location.reload();
                }
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleExportChange = (selectedOption) => {
        setFormData(prevData => ({ ...prevData, export_id: selectedOption ? selectedOption.value : 0 }));
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.export_id) errors.export_id = "Export is required";
        if (!formData.start_date) errors.start_date = "Start date is required";
        if (!formData.end_date) errors.end_date = "End date is required";

        if (formData.start_date && formData.end_date) {
            const startDate = new Date(formData.start_date);
            const endDate = new Date(formData.end_date);
            if (endDate < startDate) {
                errors.end_date = "End date cannot be earlier than the start date";
            }
        }

        return errors;
    };

    const handleClear = () => {
        setFormData({
            export_id: 0,
            user_id: userID,
            start_date: '',
            end_date: ''
        });
        setFormErrors({});
    };

    return (
        <Col sm="12">
            <Form onSubmit={handleSubmit}>
                <Card>
                    <Row className="card-block justify-content-center align-items-center">
                        <Col sm="12" className="d-flex justify-content-center align-items-center">
                            <h2 className="text-center">Export Raw Data</h2>
                        </Col>
                        <Col xl="8">
                            <Card className="d-flex justify-content-center" xl={6}>
                                <FormGroup row>
                                    <Col sm={12}>
                                        <Form>
                                            <CardBody>
                                                <Label for="exportSelect">Select Export</Label>
                                                <Col sm={10}>
                                                    <Select
                                                        className="basic-single"
                                                        value={exportOptions.find(option => option.value === formData.export_id)}
                                                        isSearchable={true}
                                                        name="export_id"
                                                        id="exportSelect"
                                                        options={exportOptions}
                                                        onChange={handleExportChange}
                                                    />
                                                    {formErrors.export_id && <div className="text-danger">{formErrors.export_id}</div>}
                                                </Col>
                                            </CardBody>
                                        </Form>
                                    </Col>
                                    <Col sm={6}>
                                        <Form>
                                            <CardBody>
                                                <Label for="start_date">Start Date</Label>
                                                <Col sm={10}>
                                                    <Input
                                                        id="start_date"
                                                        type="date"
                                                        name="start_date"
                                                        onChange={handleInputChange}
                                                        value={formData.start_date}
                                                    />
                                                    {formErrors.start_date && <div className="text-danger">{formErrors.start_date}</div>}
                                                </Col>
                                            </CardBody>
                                        </Form>
                                    </Col>
                                    <Col sm={6}>
                                        <Form>
                                            <CardBody>
                                                <Label for="end_date">End Date</Label>
                                                <Col sm={10}>
                                                    <Input
                                                        id="end_date"
                                                        type="date"
                                                        name="end_date"
                                                        onChange={handleInputChange}
                                                        value={formData.end_date}
                                                    />
                                                    {formErrors.end_date && <div className="text-danger">{formErrors.end_date}</div>}
                                                </Col>
                                            </CardBody>
                                        </Form>
                                    </Col>
                                </FormGroup>
                            </Card>
                            <CardFooter className="text-center">
                                <Button type="submit" color="btn btn-outline-info">Export Data</Button>
                                <Button color="btn btn-outline-danger" onClick={handleClear} style={{ marginLeft: 10 }}>Clear</Button>
                            </CardFooter>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </Col>
    );
};

export default ExportDataComponent;
