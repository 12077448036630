import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import { GetDashboardDefault, GetUserProfilePicture, HeaderApi } from "../../../api/index";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { toast } from "react-toastify";
import { useSelector } from 'react-redux';
import profilepic from '../../../assets/images/user/user-dp.png';
import { formatDateToCustomFormat } from '../../../utils';

// Register Chart.js components and plugins
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const Dashboard = () => {
  const [profile, setProfile] = useState('');
  const roleIds = useSelector(state => state.auth.roles);
  const user = useSelector(state => state.auth?.user);
  const brandName = useSelector(state => state.auth.user?.brand?.name);
  const [cardData, setCardData] = useState([]);
  const [quote, setQuote] = useState('');
  const [displayedQuote, setDisplayedQuote] = useState('');
  const [viewDashboard, setViewDashboard] = useState(false);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  const fetchDashboardData = async () => {
    try {
      const requestOptions = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      };
      const response = await axios.get(GetDashboardDefault, requestOptions);
      const data = response.data;

      if (data.status === 1) {
        // Update card data
        const totalData = data.data.total.reduce((acc, item) => {
          acc[item.metric] = item.value;
          return acc;
        }, {});

        setCardData([
          { title: "Total Leads", value: totalData.leads_entry_count || 0, color: "primary" },
          { title: "Total Sanction", value: totalData.leads_credit_count || 0, color: "success" },
          { title: "Total Disbursal", value: totalData.credit_analysis_count || 0, color: "warning" },
          { title: "Total Collection", value: `₹${parseFloat(totalData.collections_sum || 0).toLocaleString()}`, color: "info" },
        ]);

        // Update chart data
        const monthlyData = data.data.monthly;
        setChartData({
          labels: monthlyData.map((item) => item.month_year),
          datasets: [
            {
              label: "Total Loan Recommended (₹)",
              data: monthlyData.map((item) => parseFloat(item.total_loan_recommended || 0)),
              backgroundColor: "rgba(54, 162, 235, 0.7)",
            },
            {
              label: "Total Received Amount (₹)",
              data: monthlyData.map((item) => parseFloat(item.total_received_amount || 0)),
              backgroundColor: "rgba(153, 201, 131, 0.7)",
            },
          ],
        });
        setViewDashboard(true);
      }
    } catch (error) {
      toast.error(error.response?.data?.error || "Error occurred", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });

      if (error.response?.status === 401) {
        // localStorage.clear();
        // window.location.reload();
      }
    }
  };


  // Fetch profile picture
  const handleView = async () => {
    try {
      const response = await axios.get(GetUserProfilePicture, {
        headers: HeaderApi,
        responseType: 'blob',
      });
      const contentType = response.headers['content-type'];
      const file = new Blob([response.data], { type: contentType });
      const fileURL = URL.createObjectURL(file);
      setProfile(fileURL);
      console.log('Profile Picture:', fileURL);
    } catch (error) {
      console.error('Error fetching profile picture:', error);
      toast.error('Profile picture does not exist');
    }
  };


  useEffect(() => {
    getQuoteOfTheDay();
    if (roleIds.includes(2)) {
      fetchDashboardData();
      handleView();
    }
  }, []);

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Monthly Collection Overview",
      },
      datalabels: {
        anchor: "end",
        align: "top",
        formatter: (value) => `₹${value.toLocaleString()}`,
        color: "#000",
        font: {
          weight: "bold",
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => `₹${value.toLocaleString()}`,
        },
      },
    },
  };

  const quotes = [
    "The only way to do great work is to love what you do. - Steve Jobs",
    "Success is not the key to happiness. Happiness is the key to success. - Albert Schweitzer",
    "Your limitation—it’s only your imagination.",
    "Push yourself, because no one else is going to do it for you.",
    "Great things never come from comfort zones.",
    "Dream it. Wish it. Do it.",
    "Success doesn’t just find you. You have to go out and get it.",
  ];

  // Function to get a quote based on the day of the year
  const getQuoteOfTheDay = () => {
    const today = new Date();
    const dayOfYear = today.getDate(); // Get day of the month
    const quoteIndex = dayOfYear % quotes.length; // Get a quote from the array based on the day
    setQuote(quotes[quoteIndex]);
  };

  // Typing animation effect with a delay
  useEffect(() => {
    const typingEffect = () => {
      let i = 0;
      const quoteLength = quote.length;
      let newDisplayedQuote = '';

      const typeInterval = setInterval(() => {
        newDisplayedQuote += quote[i];
        setDisplayedQuote(newDisplayedQuote);
        i++;

        if (i === quoteLength) {
          clearInterval(typeInterval); // Stop when all characters are typed
        }
      }, 100); // Adjust typing speed here (100ms per character)
    };

    if (quote) {
      // Delay the start of the typing effect by 5 seconds
      setTimeout(() => {
        typingEffect(); // Trigger typing effect after 5 seconds delay
      }, 5000); // 5000ms = 5 seconds
    }
  }, [quote]); // This will run the effect every time the quote changes

  return (
    <> {(viewDashboard && roleIds.includes(2)) ?
      <Container fluid style={{ padding: "20px" }}>
        {/* Cards Section */}
        <Row>
          {cardData.map((card, index) => (
            <Col xs="12" sm="6" md="3" key={index}>
              <Card className={`bg-${card.color} text-white text-center mb-4`}>
                <CardBody>
                  <CardTitle tag="h5">{card.title}</CardTitle>
                  <h2>{card.value}</h2>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Chart Section */}
        <Row>
          <Col xs="12">
            <Card className="shadow-sm">
              <CardBody>
                <CardTitle tag="h5" className="mb-4">
                  Total Collection Over Months
                </CardTitle>
                <Bar data={chartData} options={chartOptions} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      :
      <div
        style={{
          fontFamily: 'Arial, sans-serif',
          margin: 0,
          padding: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            background: '#fff',
            borderRadius: '15px',
            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
            width: '500px',
            padding: '30px',
            textAlign: 'center',
          }}
        >
          <div
            style={{
              width: '140px',
              height: '140px',
              borderRadius: '50%',
              padding: '1px',
              boxSizing: 'content-box',
              margin: '0 auto',
              background: 'linear-gradient(45deg, #FF5733, #FFC300, #2575fc)', // 3-color gradient background
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)', // Optional glowing effect
              animation: 'rotateGradient 1s linear infinite', // Faster rotation
            }}
          >
            <img
              src={profile || profilepic}
              alt="User Avatar"
              style={{
                borderRadius: '50%',
                width: '120px',
                height: '120px',
              }}
            />
          </div>
          <h2 style={{ color: '#333', marginBottom: '10px', marginTop: '10px' }}>
            Welcome Back, {localStorage.getItem('name')}!
          </h2>
          <p style={{ color: '#555', fontSize: '16px', marginBottom: '20px' }}>
            We’re happy to see you again. Explore your dashboard and manage your tasks seamlessly!
          </p>
          <p style={{ fontSize: '18px', fontStyle: 'italic', color: '#2575fc', marginBottom: '20px' }}>
            "{displayedQuote}"
          </p>
          <div
            style={{
              marginTop: '20px',
              fontSize: '14px',
              color: '#888',
              animation: 'glowAnimation 1.5s ease-in-out infinite alternate',
            }}
          >
            <p>
              Last login:{' '}
              <span style={{ color: '#2575fc' }}>
                {formatDateToCustomFormat(user?.user_last_login_datetime)}
              </span>
            </p>
            <p>Have a productive day ahead!</p>
            <p
              style={{
                fontSize: '18px',
                fontWeight: 'bold',
                color: '#2575fc',
                fontStyle: 'Italic',
                animation: 'glowAnimation 5s ease-in-out infinite alternate',
              }}
            >
              {brandName}
            </p>
          </div>
        </div>
      </div>}
      <style>
        {`
    @keyframes rotateGradient {
      0% {
        background: linear-gradient(45deg, #FF5733, #FFC300, #2575fc);
      }
      100% {
        background: linear-gradient(135deg, #FF5733, #FFC300, #2575fc);
      }
    }
  `}
      </style>

    </>
  );
};

export default Dashboard;
